import {
    ApiDataResponse,
    BaseApiResponse,
} from '@frontend/jetlend-core/src/models/api';
import {
    IAccountInfoApiModel,
    AccountSwitchPayload,
    IAccountUsersApiModel,
} from '@app/models/common/account';
import {
    apiGetRequest,
    apiPostRequest,
} from '@app/services/client/api';

export async function apiGetAccountUsersData(): Promise<ApiDataResponse<IAccountUsersApiModel>> {
    return apiGetRequest('/common/api/account/users');
}

export async function apiGetAccountInfo(): Promise<ApiDataResponse<IAccountInfoApiModel>> {
    // await testBadResponse(401);

    // return await testResponse<IAccountInfoApiModel>({
    //     client_id: 1,
    //     client_type: ClientType.Investor,
    //     name: 'Екатерина',
    //     phone: '+7 *** ***-**-84',
    //     user_id: 1,
    //     account_type: AccountType.Regular,
    // });

    return apiGetRequest('/common/api/account/info');
}

export async function apiPostAccountSwitch(data: AccountSwitchPayload): Promise<BaseApiResponse> {
    return apiPostRequest('/common/api/account/switch', data);
}

export async function apiPostAccountLogout(): Promise<BaseApiResponse> {
    return apiPostRequest('/common/api/account/logout', {});
}