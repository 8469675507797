import {
    DependencyList,
    MutableRefObject,
    useLayoutEffect,
} from 'react';

/**
 * Данный хук устарел и является некорректным решением.
 *
 * Используйте className="h-contents" для корректной работы узлов / оберток.
 * Подробнее про `display: contents`: https://stasonmars.ru/html-css/bolee-dosutpnaya-razmetka-s-display-contents/
 *
 * @example
 * Вместо
 * useContainerWrapper(childrenWrapperRef);
 * return (
 *     <div ref={childrenWrapperRef} className="h-contents">{children}</div>
 * );
 *
 * return <div ref={childrenWrapperRef} className="h-contents">{children}</div>;
 *
 * @deprecated
 * @category Hooks
 */
export function useContainerWrapper(containerRef: Readonly<MutableRefObject<HTMLElement|undefined|null>>, deps?: DependencyList) {
    useLayoutEffect(() => {
        const element = containerRef.current;
        if (!element) {
            return;
        }

        function reset() {
            if (!element) {
                return;
            }

            element.style.display = undefined as never;
            element.style.flex = undefined as never;
            element.style.flexDirection = undefined as never;
            element.style.justifyContent = undefined as never;
        }

        reset();

        if (element.children.length === 0) {
            element.style.display = 'inline';
        } else if (element.children.length === 1) {
            const child = element.children.item(0);
            if (!child) {
                return;
            }

            const computedStyles = window.getComputedStyle(child);
            const computedDisplay = computedStyles.display;

            if (child.tagName === 'IMG' && computedDisplay === 'inline') {
                element.style.display = 'inline-block';
            } else if (computedDisplay === 'flex') {
                element.style.display = 'flex';
                element.style.flex = computedStyles.flex;
                element.style.flexDirection = computedStyles.flexDirection;
                element.style.justifyContent = computedStyles.justifyContent;
            } else if (computedDisplay === 'inline-flex') {
                element.style.display = 'inline-block';
            } else {
                element.style.display = computedDisplay;
            }
        }

        return () => {
            reset();
        };
    }, [ containerRef, ...(deps || []) ]);
}