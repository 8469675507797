import { ClientType } from '@app/models/common/common';

export const CLIENT_TYPE_API_MAP: Partial<Record<ClientType, string>> = {
    [ClientType.INVESTOR]: 'invest',
    [ClientType.BORROWER]: 'lend',
    [ClientType.PARTNER]: 'partner',
};

export function buildClientApiEndpoint(clientType: ClientType, endpoint: string): string {
    return  `~/${CLIENT_TYPE_API_MAP[clientType]}${endpoint}`;
}

