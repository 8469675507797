import { ActionCreatorType } from '@frontend/jetlend-core/src/store/actions';
import { call } from 'redux-saga/effects';
import { simpleSagaHandler } from '@frontend/jetlend-core/src/ducks/saga';
import { safe } from '@frontend/jetlend-core/src/utils/globalUtils';

export const VERSION = 2;
export const PREFIX = 'analytics';

function sendEventSafe(name) {
    safe(() => (window as any).dataLayer.push({
        'event': name,
        'features': JSON.stringify((window as any).USER_FEATURES ?? '{}'),
    }));
}

function* sendEventSaga(action) {
    const name = action.value;

    console.log('SEND_EVENT', name);

    yield call(sendEventSafe, name);
}

export const sendEventHandler = simpleSagaHandler(PREFIX, 'SEND_EVENT', sendEventSaga);
export const sendEvent = sendEventHandler.action as ActionCreatorType<string>;
